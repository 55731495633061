.headerCarousel {
  height: 450px;
  object-fit: cover;
  width: 100%;
}
.cartItems {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  padding: 5px;
  border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
  padding-bottom: 10px;
}

.cartItemButton {
  background-color: var(--secondary-color);
  width: 25px;
  height: 25px;
  color: #fff;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  line-height: 1.5;
}

.cartItemButtonDrawer {
  background-color: var(--secondary-color);
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}

.cartItem {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cartItemRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cartItemBox {
  height: 45px;
  width: 45px;
  margin: 8px;
  margin-left: 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartItemOptions{
  opacity: 0.5 !important;
  font-size: 13px;
  word-break: break-all;
}
.cartItemDelete {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.cartItemInputNumber {
  padding: 1px 2px !important;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  border: 0;
  appearance: none;
  height: 22px;
  font-size: 11px;
  width: 20px;
  text-align: center;
}

.cartItemInputNumberDrawer {
  padding: 3px 0px !important;
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  border: 0;
  border-radius: 3px;
  appearance: none;
  height: 22px;
  font-size: 11px;
  width: 20px;
  text-align: center;
}

.cartItemInputButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cartItemInputNote {
  flex: 1;
  margin-right: 10px;
  min-height: 30px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  outline: none;
  border: 0;
  appearance: none;
  padding-left: 10px;
  padding-right: 10px;
}
.cartItemPrice{
  width: 90px;
  padding: 0 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* --------- Menu Component -------------- */

.menuItem{
  border-radius: 1rem !important;
  overflow: hidden;
  position: relative !important;
  box-shadow:  0 4px 8px 0 rgba(0,0,0,0.2); 
}

 
 .menuItemBox {
  height: 165px; 
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  overflow: hidden; 
 

}


.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;

}

.menuItemBox {
  width: 100%;
  height: 300px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuItemBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemTitle {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px 0;
  color: #212529;
}



.price {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
 
  
}


.menuItemAddItem {
 
  background-color: #074842;
  border: none;
  color: white;
  border-radius: 100rem;
  padding: 0.3rem 0.5rem;
  font-size: 0.73rem;
  width: 85%;
  min-height: 29px;
 
}

.toggleDescriptionButton {
  background: none;
  border: none;
  color: rgb(113, 128, 150);
  font-size: 0.8rem;
  cursor: pointer;
  margin: 10px 0;
}



.itemTitle{
  word-break: break-word;
  
}
.lessItemDescription{
  overflow: hidden;
 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #212529;
 
  font-weight: 600;
  padding: 1.648px;
  font-family: Poppins, sans-serif !important;
  line-height: 1rem;
  font-size: .8rem;
  height: 65px;
    display: flex;
    align-items: center;  
    justify-content: center;
    text-align: center; 
}


.menuItemAddItem {

  cursor: pointer;
  bottom: 10px;
  right: 10px;
  width: 80%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.modalLocationButton {
  margin-top: 20px;
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}
.modalLocationButton:hover {
  opacity: 0.8;
}
.modalScrollbar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 25px;
  height: 500px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-color: transparent;
  
    overflow:auto;
}

.discription {
 
    width: 85%;
    text-align: center;
    align-items: center;
    margin: 0 auto;
  
}
.centered {
  width: 100% !important;
  margin-top: 10px !important;
  line-height: 1.5;
  align-items: center !important;
  text-align: center !important;
}


.checkMenuBtn {
  margin: 10px auto !important;
  width: 100%;
  background-color: var(--secondary-color) !important;
  transition: all 250ms ease-in-out !important;
}

.checkMenuBtn:hover {
  background-color: var(--secondary-color) !important;
  opacity: 0.9 !important;
}

.removeButton{
  color: var(--secondary-color);
  cursor: pointer;
  border-bottom: 1px solid var(--secondary-color);
  margin-right: 10px;
  margin-top: 8px;
}

.removeButton:active{
  transition: 0.2s all ease-in-out;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}


@media (max-width: 475px) {
  .selectCityModal{
    width: 300px !important;
    padding: 25px !important;
  }
  .modalScrollbar {
    width: 280px
  }
  .headerCarousel{
    object-fit: initial;
    height: 300px;
  }
  .cartItemRow {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.titleDiv{
  display:flex;
  flex-direction:column;
   text-align: left;
   width: 90%;
}
@media (max-width: 350px) {
  .selectCityModal{
    width: 260px !important;
    padding: 25px 20px !important;
  }
  .modalScrollbar {
    width: 220px
  }
  .cartItemRow {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .titleDiv{
    display:flex;
    flex-direction:column;
     flex: 1;
     text-align: center;
     width: 120px;
  }
}

@media (min-width: 475px) and (max-width: 1900px) {
  .titleDiv{
    display:flex;
     flex: 1;
     text-align: left;
     width: 100px;
  }
}