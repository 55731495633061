.ComponentWrapper{
    width: 64%;
    display: flex;
    background-color: white;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px 20px 20px;
    box-sizing: border-box;
    margin: 20px 20px 10px 20px;
}
.MainWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #e2e2e2;
    border-radius: 8px;
   
    padding:10px 20px;

}

@media only screen and (max-width: 500px) {
    .ComponentWrapper{
        justify-content: center;
        padding: 0px;
        width: 100%;
        margin: 20px 0px 0px 0px;
        box-shadow: none;
    }
    .MainWrapper{
        width: 80%;
    }
  }