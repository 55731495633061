/* --------- Second Grid Cart CSS --------- */

.gridCart {
  padding: 10px;
  padding-left: 20px;
  padding-top: 0;
}

.cartColumn {
  text-align: center;
  padding-top: 20px;
}

.emptyCarts {
  width: 70%;
  height: auto;
  margin-top: 100px;
}
.emptyCarts{
  /* filter: invert(93%) sepia(24%) saturate(702%) hue-rotate(64deg) brightness(92%) contrast(92%); */
  filter: invert(1%) sepia(142%) saturate(197%) hue-rotate( 
    124deg) brightness(100%) contrast(148%);
  }

.cartCard {
  /* overflow-y: scroll; */
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* margin-right: 1px; */
  /* padding-right: 5px;  */
  box-sizing: content-box; /* So the width will be 100% + 17px */
  
}
/* -------------- Scroll bar in cart on menu page ----------- */
.cartCard::-webkit-scrollbar{
  width: 0.4em !important;
  direction: rtl;
}

.cartCard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--primary-color) !important;
}
 
.cartCard::-webkit-scrollbar-thumb {
  border-radius: 2px !important;
  background-color: var(--secondary-color) !important;
}

.cartTotal {
  padding-top: 20px;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
}

.cartAmount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}


.itemModal {
  position: fixed;
  color: var(--primary-color);
  bottom: 0;
  width: 100%;
  /* background-color: rgb(238, 164, 74); */
  background-color: var(--secondary-color);
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.itemModalBody {
  width: 90%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  left: 5%;
  align-items: center;
}

.itemModalBodyContent {
  display: flex;
  align-items: center;
}
.itemModalQty {
  padding-left: 5px;
}

.itemModalViewCart {
  font-size: 18px;
}

/* --------------- Drawer ------------- */

.drawermain {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  height: 100% !important;
}

/* .drawerSummary::-webkit-scrollbar {
  display: none !important;
} */

.drawerSummary {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* padding-left: 5px; */
  width: min-content;
  /* padding-right: 15px; */
}
.drawerSummaryHead {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: solid rgba(0, 0, 0, 0.05) 1px;
}
.drawerSummaryCart {
  flex: 1;
  /* overflow-y: auto; */
  /* width: 100%; */
  overflow-x: hidden;
  /* margin-right: 5px; */
  padding-right: 5px;
}
.mobileCartAmount{
  font-size: 0.8rem !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.mobileCartAmount h4{
  margin-right: 0px !important;
}
/* .drawerSummaryCart::-webkit-scrollbar {
  display: none;
} */
.drawerSummaryCart::-webkit-scrollbar{
  width: 0.3em !important;
}

.drawerSummaryCart::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--primary-color) !important;
}
 
.drawerSummaryCart::-webkit-scrollbar-thumb {
  border-radius: 2px !important;
  background-color: var(--secondary-color) !important;
}

.drawerSummaryCartItem {
  height: 90px;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 10; */
}
.drawerSummaryPromo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.drawerSummaryPromoInput {
  margin-right: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  outline: none;
  border: 0;
  appearance: none;
  height: 30px;
  padding-left: 20px;
  /* width: 90%; */
}
.drawerSummaryTotal {
  /* height: 90px; */
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5px;
  margin-bottom: 10px;
}
/* .drawerSummaryDiscount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
} */
.drawerSummarySubTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.mobileTotlaItemAmount{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.drawerDetails {
  padding-left: 5px;
  padding-right: 5px;
  border-left: solid rgba(0, 0, 0, 0.05) 1px;
  box-shadow: -1px 0px 16px 7px rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow-y: auto;
}
.drawerDetails::-webkit-scrollbar {
  width: 0.4em;
}
 
.drawerDetails::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px var(--secondary-color);
}
 
.drawerDetails::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--secondary-color);
}

.drawerDetailsHead {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.drawerDetailsHeading {
  text-align: left;
  padding-bottom: 10px;
  /* border-bottom: solid rgba(0, 0, 0, 0.05) 1px; */
  margin-bottom: 0;
}
.drawerDetailsPayment::-webkit-scrollbar{
  width: 0.5em !important;
}

.drawerDetailsPayment::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px var(--primary-color) !important;
}
 
.drawerDetailsPayment::-webkit-scrollbar-thumb {
  border-radius: 2px !important;
  background-color: var(--secondary-color) !important;
}

.requiredText{
  font-weight: 200 ;
  /* color: var(--secondary-color); */
  color: red;
  font-size: 11px;
}

.drawerDetailsPayment {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
}
.drawerDetailsPaymentMethod {
  padding: 5px;
  border: solid black 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}
.drawerDetailsPaymentMethod1 {
  font-size: 12px;
  margin: 5px;
}
.drawerDeliveryDetailsBox {
  width: 100%;
}
.drawerDeliveryDetailsBoxHeading {
  margin-bottom: 5px;
  margin-top: 15px;
}
.drawerDeliveryDetailsBoxInput {
  margin-right: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  outline: none;
  border: 0;
  appearance: none;
  height: 30px;
  padding-left: 20px;
  width: 90%;
}
.drawerDetailsOrderConfirmation {
  height: 90;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 15px;
}
.drawerDetailsOrderButton {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.placeOrderButton {
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}
.placeOrderButton:hover {
  opacity: 0.8;
}
.customOrderButton {
  border-color: var(--secondary-color) !important;
  /* color: var(--secondary-color) !important; */
  color: black !important;
  /* background-color: var(--primary-color) !important; */
  background-color: white !important;
}
.customOrderButton:hover {
  background-color: rgba(160, 160, 160, 0.151) !important;
}

.checkOutButton{
  display: none ;
}
.customTabs {
  /* color: var(--secondary-color) !important; */
  /* color:#eebb74 !important; */
  font-weight: bold;
  /* color: black !important; */
  /* border-color: var(--secondary-color) !important; */
  /* border-bottom: 4px solid var(--secondary-color) !important; */
  /* position: relative; */
  /* z-index: 999; */
}
.scrollSpyStyle {
  display: block;
  list-style-type: none !important;
  margin-block-start: 0;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  background: #074242;
  
}
.backIconCheckOut{
  display: none !important;
}
.mobileTaxDetails{
  display: none;
}
.EmptyComponent{
  height:50vh;
display: flex;
justify-content: center;
align-items: center;
}
@media (max-width: 920px) {
  .drawerDetails{
    min-width: 380px ;
   }
}
@media (max-width: 920px) {
  .drawerDetails{
    display: none;
   }
   .checkOutButton{
    display: block ;
  }
  .backIconCheckOut{
    display: inline-block !important;
  }
  .mobileTaxDetails{
    display: block;
  }
  .drawerSummarySubTotal {
    font-size: 0.8rem;
  }
  .drawerSummaryCart{
    font-size: 0.8rem ;
    /* padding: 0 10px; */
  }
}
@media (max-width: 475px) {
  .drawerDetails{
   min-width: 100% ;
  }
  .drawermain {
    justify-content: center;
    width: 75vw;
  }
  .drawerSummary{
    /* display: none; */
    width: 100%;
  }
}
@media (max-width: 335px) {
  .drawerDetails{
    min-width: 290px;
   }
   .drawerSummary{
     display: none;
     width: 100%;
   }
   .drawermain {
    /* width: 60vw; */
    justify-content: center;
  }
}