/* .nav-colored { background-color:#000; }
.nav-transparent { background-color:transparent;} */

.nav-fixed {
    width: 100%;
    /* position: fixed; */
    transition: 0.3s ease-in-out;
}

.nav-active {
    /* background-color:#F5F5F5; */
    background-color: var(--primary-color);

    /* box-shadow: 5px -1px 12px -5px grey; */
}

.language-select{
    cursor: pointer;
    padding: 0 5px;
    width: 80px;
    min-height: 40px;
    border-radius: 5px !important;
    background-color: transparent !important;
    color: var(--primary-color) !important;
}
.language-select option{
    color: #000 !important;
    font-size: 15px;
}

.language-select-active option{
    color: var(--primary-color) !important;
}

.language-select-active{
    background-color: var(--secondary-color) !important;
    color: var(--primary-color) !important;
    /* color: #000; */

}
/* .language-select::after {
    color: greenyellow !important
  } */
