.receiptPage {
  background-color: rgb(240, 240, 240);
  overflow-x: hidden;
  position: relative;
}
.receiptPageHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.receiptPageLogo {
  width: 100%;
  /* padding: 10px; */
  /* padding-top: 50px; */
  margin-top: 55px;
  text-align: center;
  background-color: white !important;
  position: absolute;
}
.receiptPageHeaderLogo {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  object-fit: cover;
}
.receiptPageBody {
  padding-top: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 80px;
}
.receiptProfile {
  margin-top: 10px;
  /* background-color: #fff; */
}

.receiptProfileData {
  background-color: white !important;
}
.receiptProfileImage {
  height: 100%;

  width: 100%;
  object-fit: cover;
}
.receiptProfileInvalid {
  padding: 20px;
}
.receiptProfileInvalidOrder {
  padding-top: 15px;
  padding-bottom: 15px;
}
.receiptProfileInvalidOrderDetail {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  margin-bottom: 20px;
}
.receiptProfileValid {
  padding: 20px;
}
/* .receiptProfileValid:before{
  content: "Thank You For Ordering";
  font-weight: bold;
  color: var(--primary-color);
  position: absolute;
  top: 90px;
  left: 30% !important;
  font-size: xxx-large;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 4px 0 #000, 3px 2px 0 #000;
} */
.receiptProfileValidOrder {
  padding-top: 15px;
  padding-bottom: 15px;
}
.receiptProfileDataTop {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}
.receiptInvoiceLogo {
  font-size: 60px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--secondary-color) !important;
}
.receiptInvoice {
  /* background-color: var(--primary-color) !important; */
  background-color: #fff !important;
  padding: 30px;
}

.receiptInvoiceHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.receiptInvoiceHeading {
  font-weight: bold;
}

.receiptInvoiceBody {
  display: flex;
  justify-content: space-between;
}
.receiptOrderStatus {
  padding: 5px 10px;
  border-radius: 14px;
  color: #fff;
  font-weight: 500;
}
.receiptInvoiceTotalAmount {
  font-weight: bold;
  min-width: 65px;
  text-align: right;
}
.receiptInvoiceAmount {
  display: flex;
  justify-content: space-between;
}
.receiptInvoiceTotal {
  font-weight: bold;
  min-width: 65px;
  text-align: right;
}
.receiptChatBox {
  /* background-color: var(--primary-color) !important; */
  background-color: #fff;
  padding: 30px;
}
.receiptChatBoxLogo {
  font-size: 60px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--secondary-color) !important;
}
.receiptChatBoxData {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
}
.receiptChatBoxDetails {
  font-weight: 500;
  padding-left: 10px;
}
.receiptPageFooter {
  background-color: var(--secondary-color) !important;
  padding: 15px;
  margin-top: 50px;
  justify-content: flex-end;
}

.receiptPageFooterItem {
  font-weight: 500;
  padding-left: 10px;
  color: white;
}
