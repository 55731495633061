.dialogBox {
  overflow-x: hidden;
  overflow-y: auto;
}

.dialogBoxContent{
  padding: 0 !important;
  overflow-x: hidden;
  min-width: 600px;
}
.dialogBoxContent::-webkit-scrollbar {
  width: 0.5em;
}
 
.dialogBoxContent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px var(--primary-color);
}
 
.dialogBoxContent::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--secondary-color);
}
.dialogTitle {
  padding: 0 !important;
  overflow-x: hidden;
  min-width: 600px;
}
.dialogTitleBody {
  width: 100%;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.08);
  position: relative;
  
}
.dialogTitleImage {
  width: 100%;
  height: 100%;
  /* position: relative; */
  object-fit: cover;
  border-top-left-radius: 4px;
  /* border-top-right-radius: 1px; */
}
.dialogTitleBottom {
  height: 140px;
  background: linear-gradient(transparent, var(--primary-color));
  position: absolute;
  bottom: 9px;
  width: 100%;
  
}
.dialogTitleBottom2 {
  height: 10px;
  /* background: white; */
  background: var(--primary-color);
  position: absolute;
  bottom: 0;
  width: 100%;
}
.dialogTitleContent {
  position: absolute;
  bottom: 5px;
  width: 90%;
  left: 5%;
}
.dialogTitleContentData {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.dialogTitleHeading {
  padding: 0;
  margin: 0;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dialogTitlePara {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.dialogTitlePara2 {
  padding: 0;
  margin: 0;
  font-size: 14px;
  opacity: 0.9;
}
.dialogTitleClose {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  /* background-color: white; */
  background-color: var(--primary-color);
  position: absolute;
  top: 10px;
  right: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.dialogContentBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px;
}
.dialogContentForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
/* .dialogActions {
  margin-top: 15px !important;
} */
.itemButtons{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  margin-bottom: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.dialogActionsBody {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  margin-left: 22px;
}

/* .menuQuestions::-webkit-scrollbar {
  width: 0.5em;
}
 
.menuQuestions::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px var(--primary-color);
}
 
.menuQuestions::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--secondary-color);
} */
.dialogActionsButton {
  max-width: 35px ;
  max-height: 30px ;
  padding-left: 0px ;
  padding-right: 0px ;
  cursor: pointer;
  color: var(--primary-color) ;
  background-color: var(--secondary-color);
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  transition: 0.4s all ease-in-out ;
}
.dialogActionsButton:hover {
  opacity: 0.9;
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
  border: 2px solid var(--secondary-color);
}
.dialogActionsButton:active{
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}

.dialogActionsInput {
  margin-left: 5px;
  margin-right: 5px;
  width: 30px;
  text-align: center;
  color: var(--secondary-color);
  font-size: 14px;
}
.dialogActionsInput:focus{
  border-color: var(--secondary-color) !important;
}
.dialogActionsInput:active{
  border-color: var(--secondary-color) !important;
}

.placeOrderButton {
  padding: 0 0 !important;
  min-height: 35px !important;
  min-width: 200px !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  background-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color);
  transition: 0.4s all ease-in-out !important;
}
.placeOrderButton:hover {
  opacity: 0.8;
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
  border: 2px solid var(--secondary-color);
}
.instructionBox{
  display: flex;
  flex-direction: column;
  padding: 0px 28px;
  margin-top: 20px;
}
.instructionHeading{
margin: 0;
padding: 5px 0px;
font-size: 16px;

}
.instructionSubHeading{
margin: 0;
padding: 5px 0px;
font-size: 14px;
}
.instructionTextArea{
  width: auto;
  resize: none;
  padding: 10px;
}

@media (max-width: 920px) {
  .dialogBoxContent{
    min-width: 350px !important;
  }
  .placeOrderButton{
    min-width: 130px !important;
    margin-right: 10px !important;
  }
}
@media (max-width: 475px) {
  .dialogBoxContent{
    min-width: 320px !important;
    /* padding: 25px 20px !important; */
  }
  .placeOrderButton{
    min-width: 150px !important;
    margin-right: 10px !important;
  }
}
@media (max-width: 375px) {
  .dialogBoxContent{
    min-width: 280px !important;
  }
  .placeOrderButton{
    min-width: 130px !important;
    margin-right: 10px !important;
  }
}