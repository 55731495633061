.footer {
  height: 30px;
  border-top-width: 2px;
  border-top-color: var(--primary-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  color: var(--primary-color) !important;
  justify-content: space-between;
  padding: 15px;
}
.language {
  cursor: pointer;
  margin-right: 15px;
  font-size: 15px;
  color: var(--secondary-color);
}
.footerInfo{
  text-align: left; 
  display: flex;
}


@media (max-width: 420px) {
  .footer{
    /* height: 45px !important; */
    /* width: 100%; */
    /* padding: 18px 6px; */
    text-align: center !important;
   }
   .footerInfo{
     display: none;
   }
   .footerCredits{
    width: 100%;
    text-align: center !important;
  }
}
